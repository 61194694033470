import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import _ from 'lodash';
import { formatDateWithoutDay, sameMonthYear } from '../../../utils/dateUtil';
import { incidentsOn3MonthsTemplate } from '../../../utils/constants';

export default function MonthsPagination({ incidentsOn3Months, onClick }) {

    const handlePreviousPageClick = () => {
        const previousDate = new Date(incidentsOn3Months[0].date);
        previousDate.setMonth(previousDate.getMonth() - 3);
        const incidentsOnPrevious3Months = incidentsOn3MonthsTemplate(previousDate);
        onClick(incidentsOnPrevious3Months);
    }

    const handleNextPageClick = () => {
        const nextDate = new Date(incidentsOn3Months[0].date);
        nextDate.setMonth(nextDate.getMonth() + 3);
        const incidentsOnNext3Months = incidentsOn3MonthsTemplate(nextDate);
        onClick(incidentsOnNext3Months);
    }

    return (
        <nav className="px-4 justify-end flex flex-row items-center text-md font-medium text-gray-500">
            <div className="flex-1 flex">
                <button
                    type="button"
                    className="bg-transparent border rounded inline-flex items-center hover:text-indigo-700 disabled:cursor-not-allowed disabled:text-gray-500"
                    onClick={handlePreviousPageClick}
                >
                    <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>

            {incidentsOn3Months && (
                <div className='px-3'>{formatDateWithoutDay(incidentsOn3Months[2].date)} to {formatDateWithoutDay(incidentsOn3Months[0].date)}</div>
            )}

            <div className="flex-1 flex justify-end">
                <button
                    type="button"
                    className="bg-transparent border rounded inline-flex items-center hover:text-indigo-700 disabled:cursor-not-allowed disabled:text-gray-500"
                    disabled={sameMonthYear(incidentsOn3Months[0].date, new Date())}
                    onClick={handleNextPageClick}
                >
                    <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </nav>
    )
}
