import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import MissingLicense from './screens/Errors/MissingLicense';
import { Toaster } from './toaster';

import React from 'react';
import { Home } from './screens/Home';
import { IncidentHistory } from './screens/History';
import BaseLayout from './layouts/BaseLayout';
import IncidentReport from './screens/IncidentReport';

function App() {

  return (
    <div className="app h-screen">
      <Router>

        <Switch>
          <Route exact={true} path="/unauthorized" component={Unauthorized} />
          <Route exact={true} path="/missing-license" component={MissingLicense} />
          <Route exact={true} path="/not-found" component={NotFound} />

          <Route exact={true} path="/incident/:incidentId" component={IncidentReport} />
          <BaseLayoutRoute exact={true} path="/" component={Home}/>
          <BaseLayoutRoute exact={true} path="/subscriptions/confirm/:subscriptionId" component={Home}/>
          <BaseLayoutRoute exact={true} path="/history" component={IncidentHistory} />
        </Switch>

      </Router>
      <Toaster />
    </div>
  );
}

function BaseLayoutRoute({
  component: Component,
  path,
  ...rest
}) {

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => (
        <BaseLayout {...props}>
          <Component {...props} />
        </BaseLayout>
      )}
    />
  );
}

export default App;
