import { useEffect, useState } from "react"
import { getLocaleTimeString, getDateDifference } from "../../../utils/dateUtil";


export default function TooltipsOutageComponent({ event }) {
    const [dateDiff, setDateDiff] = useState();

    useEffect(() => {
        if (event?.startTime && event?.endTime) {
            setDateDiff(getDateDifference(event.startTime, event.endTime));
        }
    }, [])

    return (
        <div className="bg-gray-100 rounded mb-3 px-3 py-2 w-full font-semibold flex flex-row">
            <span className="flex-1">Outage</span>
            {dateDiff ?
                <>
                    <span className="px-2">{dateDiff.hour} hrs</span>
                    <span className="px-2">{dateDiff.minute} mins</span>
                </>
                :
                <>
                    <span className="px-2">{getLocaleTimeString(event.startTime)}</span>
                    <span className="px-2"> - </span>
                </>
            }
        </div>
    )
}