import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react';
import { InboxIcon, XIcon } from "@heroicons/react/outline";
import EmailComponent from './emailComponent';

const subscriptionNavigations = [
  { name: "Subscribe via Email", icon: <InboxIcon className="h-5 w-5" aria-hidden="true" />, children: <EmailComponent /> },
]

const SubscriptionComponent = () => {
  const [subscription, setSubscription] = useState(subscriptionNavigations[0]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? 'text-white' : 'text-white/90'}
                border rounded-md shadow px-6 py-2 mt-6 bg-green-600 text-sm text-white font-medium uppercase`}
          >
            Subscribe to updates
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-1 transform " style={{ width: '310px' }}>
              {({ close }) => (
                <div className="overflow-hidden border rounded-md shadow bg-gray-50">
                  <div className="grid grid-cols-7 border-b border-gray-300">
                    {subscriptionNavigations.map((subscriptionNavigation, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          className={`col-span-1 inline-flex items-center px-3 py-2 border-r border-gray-300 
                            ${subscriptionNavigation.name === subscription.name ? 'bg-gray-400 text-white' : 'bg-gray-50 text-gray-400'}`}
                          onClick={() => { setSubscription(subscriptionNavigation) }}
                        >
                          {subscriptionNavigation.icon}
                        </button>
                      )
                    })}
                    <button
                      type="button"
                      className={`col-end-8 col-span-1 inline-flex items-center px-3 py-2 border-l border-gray-300 bg-gray-50 text-gray-400 hover:text-gray-500`}
                      onClick={() => { close() }}
                    >
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="px-3 py-3">
                    {subscription.children}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default SubscriptionComponent;