import axios from 'axios';
import { config } from '../utils/config';
import { apiErrorHandler } from './apiErrorHandler';
import { saveAs } from 'file-saver';
/**
 * Custom handler for status code
 * @callback StatusCodeHandler
 */

/**
 * @typedef {Object} StatusCodeHandlers
 * @prop {number} statusCode
 * @prop {StatusCodeHandler} handler
 */

/**
 * @typedef {Object} ApiOptions
 * @prop {Ojbect} axiosParams - see axios documentation for possible values
 * @prop {StatusCodeHandlers[]} statusCodeHandlers
 */

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : '';
}

export function setStatusApiKey() {
  axios.defaults.headers.common['InternalApiKey'] = config.statusApiKey ? config.statusApiKey : ''
}

export const BaseUrl = {
  Api: config.apiBaseUrl,
  Identity: config.identityBaseUrl,
  Centerpoint: config.centerpointBaseUrl
};

/**1
 * @param {string} path
 * @param {string} baseUrl
 * @param {ApiOptions} options
 */
export const get = async (path, baseUrl = BaseUrl.Api, options = {}) => {
  const axiosParams = options?.axiosParams || {};
  try {
    const result = await axios({
      method: 'get',
      url: `${baseUrl}/${path}`,
      ...axiosParams
    });

    return result.data;
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};

/**
 * @param {string} path
 * @param {string} filename
 * @param {string} baseUrl
 * @param {ApiOptions} options
 */
export const download = async (path, filename, baseUrl = BaseUrl.Api, options = null) => {
  const axiosParams = options?.axiosParams || {};
  try {
    const result = await axios({
      method: 'get',
      responseType: 'blob',
      url: `${baseUrl}/${path}`,
      ...axiosParams
    });

    saveAs(result.data, filename);
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};

/**
 * @param {string} path
 * @param {object} data
 * @param {ApiOptions} options
 * @param {string} baseUrl
 * @returns
 */
export const put = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {};
  try {
    const result = await axios({
      method: 'put',
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    });
    return result.data;
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};

/**
 * @param {string} path
 * @param {Object} data
 * @param {ApiOptions} options
 * @param {string} baseUrl
 * @returns
 */
export const post = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {};
  try {
    const result = await axios({
      method: 'post',
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    });
    return result.data;
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};

/**
 * @param {string} path
 * @param {ApiOptions} options
 * @param {string} baseUrl
 * @returns
 */
export const destroy = async (path, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {};
  try {
    const result = await axios({
      method: 'delete',
      url: `${baseUrl}/${path}`,
      ...axiosParams
    });
    return result.data;
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};

/**
 * @param {string} path
 * @param {object} data
 * @param {ApiOptions} options
 * @param {string} baseUrl
 * @returns
 */
export const patch = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {};
  const patchData = Object.keys(data).map((key) => {
    let value = `${data[key] == null ? null : data[key]}`;

    if (Array.isArray(data[key])) {
      value = data[key];
    }

    return {
      value: value,
      path: `/${key}`,
      op: 'replace'
    };
  });
  try {
    const result = await axios({
      method: 'patch',
      url: `${baseUrl}/${path}`,
      data: {
        operations: patchData
      },
      ...axiosParams
    });
    return result.data;
  } catch (err) {
    await apiErrorHandler(err, options?.statusCodeHandlers);
  }
};
