import { useEffect, useRef, useState } from "react"
import { getEvents } from "../../api/event"
import { getServices } from "../../api/service"
import { sameDay } from "../../utils/dateUtil";
import { servicesTemplate } from "../../utils/constants";
import ServiceStatusComponent from "./components/serviceStatusComponent";
import { OutageContext } from "../../contexts";
import TooltipsComponent from "./components/tooltipsComponent";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import { searchIncidents } from "../../api/incident";
import HomeIncidentsComponent from "./components/homeIncidentsComponent";
import { useSearchParam } from 'react-use';
import toast from 'react-hot-toast';
import UnsubscribeComponent from "./components/unsubscribeComponent";
import { useParams } from "react-router-dom";
import { confirmSubscription } from "../../api/subscription";

const incidentsOnLast14DaysTemplate = [...Array(14).keys()].map(x => {
  const date = new Date();
  date.setDate(date.getDate() - x);
  return {
    date: date,
    incidents: [],
  };
});

export const Home = () => {
  const subscriptionConfirmed = useSearchParam('subscription_confirmed');
  const [events, setEvents] = useState();
  const [hasActiveEvent, setHasActiveEvent] = useState(false);
  const [services, setServices] = useState({});
  const [incidentsOnLast14Days, setIncidentsOnLast14Days] = useState([]);
  const [statusBarMouseEntered, setStatusBarMouseEntered] = useState();
  const tooltipsEventsOnDateRef = useRef();
  const [tooltipsStyle, setTooltipsStyle] = useState({
    top: '0px',
    left: '0px',
  });
  const history = useHistory();
  const { subscriptionId } = useParams();

  const loadData = async () => {
    const now = new Date();
    const day14Before = new Date();
    day14Before.setDate(day14Before.getDate() - 13);
    const searchIncidentRequest = {
      text: '',
      startTime: new Date(day14Before.getFullYear(), day14Before.getMonth(), day14Before.getDate(), 0, 0, 0),
      endTime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    }

    const [servicesResult, eventsResult, incidentsResult] = await Promise.all([
      getServices(),
      getEvents(),
      searchIncidents(searchIncidentRequest),
    ]);

    setEvents(eventsResult);

    const ss = servicesTemplate(servicesResult.services);
    eventsResult.forEach(event => {
      if (!ss[event.service]) return;

      const foundEventsOnDate = ss[event.service].eventsOn90Days.find(x => sameDay(x.date, new Date(event.startTime)));
      if (foundEventsOnDate) {
        const index = foundEventsOnDate.events.findIndex(x => x.id === event.id);
        if (index >= 0) {
          foundEventsOnDate.events[index] = event;
        } else {
          foundEventsOnDate.events.push(event);
        }
      }
    });

    const ins = incidentsOnLast14DaysTemplate;
    incidentsResult.forEach(incident => {
      const foundIndicidentOnDate = ins.find(x => sameDay(x.date, incident.createdDate));
      if (foundIndicidentOnDate) {
        const index = foundIndicidentOnDate.incidents.findIndex(x => x.id === incident.id);
        if (index >= 0) {
          foundIndicidentOnDate.incidents[index] = incident;
        } else {
          foundIndicidentOnDate.incidents.push(incident);
        }
      }

      incident.affectedServices?.forEach(affectedService => {
        if (!ss[affectedService]) return;
        const foundEventsOnDate = ss[affectedService].eventsOn90Days.find(x => sameDay(x.date, new Date(incident.createdDate)));
        if (foundEventsOnDate) {
          const index = foundEventsOnDate.incidents.findIndex(x => x.id === incident.id);
          if (index >= 0) {
            foundEventsOnDate.incidents[index] = incident;
          } else {
            foundEventsOnDate.incidents.push(incident);
          }
        }
      })
    })

    setServices(ss);
    setIncidentsOnLast14Days(ins);
  }

  const handleConfirmSubscription = async () => {
    if (subscriptionId) {
      const result = await confirmSubscription(subscriptionId);
      if (result === true) {
        history.push("/?subscription_confirmed=true");
      } else {
        history.push("/");
      }
    }
  }

  useEffect(() => {
    handleConfirmSubscription();
  }, [])

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    if (subscriptionConfirmed === 'true') {
      toast.success('Great! Your email is now subscribed to Metaforce.');
    }
  }, [subscriptionConfirmed])

  useEffect(() => {
    const interval = setInterval(() => loadData(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const activeEvents = events?.filter(x => x.isActive);
    setHasActiveEvent(activeEvents?.length > 0);
  }, [events])

  return (
    <OutageContext.Provider value={{
      statusBarMouseEntered: statusBarMouseEntered,
      setStatusBarMouseEntered: setStatusBarMouseEntered,
      tooltipsStyle: tooltipsStyle,
      setTooltipsStyle: setTooltipsStyle,
      tooltipsEventsOnDateRef: tooltipsEventsOnDateRef,
    }}>
      <div>
        <div className={"px-5 py-3 text-white mt-12 rounded " + (hasActiveEvent ? "bg-red-600" : "bg-green-600")}>{hasActiveEvent ? "Issues in progress" : "All System Operational"}</div>

        <div className="mt-12 border-gray-200 border rounded-md divide-y divide-solid">
          {
            Object.keys(services).map((service) => {
              return (
                <ServiceStatusComponent key={service} service={services[service]}></ServiceStatusComponent>
              )
            })
          }
        </div>

        <div className="mt-12">
          <h2 className="text-2xl">Past Incidents</h2>
          {
            incidentsOnLast14Days.map((incidentsOnDate, index) => {
              return (
                <HomeIncidentsComponent incidentsOnDate={incidentsOnDate} key={index} />
              )
            })
          }
        </div>

        <div className="border-gray-200 border-t mt-5">
          <button className="mt-2 border-none inline-flex items-center gap-x-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => {
              history.push("/history");
            }}
          >
            <ArrowNarrowLeftIcon className="h-4 w-4" />
            Incident History
          </button>
        </div>

        <TooltipsComponent />
        <UnsubscribeComponent />
      </div>
    </OutageContext.Provider>
  )
}