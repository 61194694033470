import { Formik } from 'formik';
import * as Yup from 'yup';
import { subscribeEmail } from '../../../api/subscription';
import toast from 'react-hot-toast';
import { useToastAction } from '../../../hooks/useToastAction';

const EmailComponent = () => {
  const emailErrorMessage = 'Please enter a valid email that you wish to have updates send to';

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(emailErrorMessage).required(emailErrorMessage),
  });

  const subscribeAction = useToastAction();

  const handleSubmitForm = async (values) => {
    subscribeAction.execute(async () => {
      const result = await subscribeEmail({ email: values.email });
      if (result) {
        toast.success(result);
      }
    }, 'Failed to subscribe via email');
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmitForm(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="text-sm">
            Get email notifications whenever Metaforce <strong>creates</strong>, <strong>updates</strong> or <strong>resolves</strong> an incident.
          </div>
          <div className="my-3">
            <label htmlFor="email" className="text-sm">
              Email address:
            </label>
            <input
              id="email"
              type="text"
              className="px-3 py-2 w-full rounded-md text-sm"
              onChange={handleChange}
              value={values.email}
            />
            <div className='text-sm text-red-600'>{errors?.email}</div>
          </div>
          <button
            type="submit"
            className="nline-flex items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-sm font-medium text-white uppercase hover:bg-green-700"
            disabled={isSubmitting}
          >
            Subscribe via Email
          </button>

        </form>
      )}
    </Formik>

  );
}

export default EmailComponent;