
export const formatDate = (d) => {
  const date = typeof d === 'string' ? new Date(d) : d;
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

export const formatDateWithoutDay = (d) => {
  const date = typeof d === 'string' ? new Date(d) : d;
  const options = { year: 'numeric', month: 'long' };
  return date.toLocaleDateString(undefined, options);
}

export const formatDateTime = (d) => {
  const date = typeof d === 'string' ? new Date(d) : d;
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options) + ', ' +  date.toLocaleTimeString();
}

export const getLocaleTimeString = (value) => {
  let date = typeof value === 'string' ? new Date(value) : value;
  return date.toLocaleTimeString();
}

export const sameDay = (d1, d2) => {
  let date1 = typeof d1 === 'string' ? new Date(d1) : d1;
  let date2 = typeof d2 === 'string' ? new Date(d2) : d2;
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export const sameMonth = (d1, d2) => {
  let date1 = typeof d1 === 'string' ? new Date(d1) : d1;
  let date2 = typeof d2 === 'string' ? new Date(d2) : d2;
  return date1.getMonth() === date2.getMonth();
}

export const sameMonthYear = (d1, d2) => {
  let date1 = typeof d1 === 'string' ? new Date(d1) : d1;
  let date2 = typeof d2 === 'string' ? new Date(d2) : d2;
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export const getDateDifference = (d1, d2) => {
  var date1 = typeof d1 === 'string' ? new Date(d1) : d1;
  var date2 = typeof d2 === 'string' ? new Date(d2) : d2;
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return {
    hour: Math.floor(timeDiff / (1000 * 3600)),
    minute: Math.floor(timeDiff / (1000 * 60) % 60),
  };
}