import { useEffect, useState } from "react";
import { formatDateWithoutDay } from "../../../utils/dateUtil";
import IncidentComponent from "./incidentComponent";

export default function HistoryIncidentsComponent({ incidentsOnMonth }) {
    const [showCollapseButton, setShowCollapseButton] = useState(false);
    const [collapseButtonText, setCollapseButtonText] = useState('');
    const [incidents, setIncidents] = useState([]);
    const sortIncidentsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    useEffect(() => {
        setShowCollapseButton(incidentsOnMonth.incidents?.length > 3 ? true : false);
        if (incidentsOnMonth.incidents?.length > 3) {
            setIncidents(sortIncidentsByDate(incidentsOnMonth.incidents).slice(0, 3))
        } else {
            setIncidents(incidentsOnMonth.incidents);
        }
    }, [incidentsOnMonth])

    useEffect(() => {
        if (incidents?.length > 3) {
            setCollapseButtonText(`- Collapse Incidents`);
        } else {
            setCollapseButtonText(`+ Show All ${incidentsOnMonth.incidents?.length} Incidents`);
        }
    }, [incidents])

    return (
        <div className="py-5" >
            <div className="border-gray-200 border-b">
                <h3 className="text-2xl">{formatDateWithoutDay(incidentsOnMonth.date)}</h3>
            </div>
            <div className="py-3">
                {
                    incidents?.length > 0
                        ?
                        incidents.map((incident) => {
                            return (
                                <IncidentComponent key={incident.id} incident={incident} />
                            )
                        })
                        :
                        <div className="text-gray-400 text-sm" >No incidents reported for this month.</div>
                }
            </div>

            {showCollapseButton && (
                <button
                    className="w-full border rounded-md shadow-sm px-6 py-3 text-sm text-gray-400"
                    onClick={() => {
                        if (incidents?.length > 3) {
                            setIncidents(sortIncidentsByDate(incidentsOnMonth.incidents).slice(0, 3))
                        } else {
                            setIncidents(incidentsOnMonth.incidents);
                        }
                    }}>
                    {collapseButtonText}
                </button>
            )}

        </div>

    )
}