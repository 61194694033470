import { useRef, useState, useContext } from "react";
import { OutageContext } from "../../../contexts";
import _ from 'lodash'

export default function StatusComponent({ x, eventsOnDate, onMouseEnter }) {
    const referenceElement = useRef();
    const [delayHandler, setDelayHandler] = useState(null);
    const { setStatusBarMouseEntered, setTooltipsStyle, tooltipsEventsOnDateRef } = useContext(OutageContext);

    const handleMouseEnter = () => {
        setDelayHandler(setTimeout(() => {
            setStatusBarMouseEntered(true);
            tooltipsEventsOnDateRef.current = _.cloneDeep(eventsOnDate);
            const rect = referenceElement.current.getBoundingClientRect();
            setTooltipsStyle({
                text: {
                    top: `${(rect.bottom + 9)}px`,
                    left: `${rect.left}px`,
                },
                arrow: {
                    top: `${rect.bottom}px`,
                    left: `${(rect.left - 5)}px`,
                }
            })
        }, 500))
    }

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
        setStatusBarMouseEntered(false);
    }

    return (
        <>
            <rect height="34" width="3" x={x} y="0" fill={eventsOnDate.events?.length !== 0 ? '#991B1B' : '#16a34a'} className="" data-html="true" tabindex="0" aria-describedby="uptime-tooltip"
                ref={referenceElement}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
            >
            </rect>
        </>
    )

}