import SubscriptionComponent from './components/subscriptionComponent';

const BaseLayout = ({ children }) => {
  return (
    <div className={'h-screen'}>
      <div className={`container max-w-screen-lg mx-auto p-10 flex flex-col`}>
        <main className="">
          <div className="mb-20">
            <div className="flex justify-between">
              <img className="h-14 w-auto"
                src="https://cdn2.hubspot.net/hubfs/4814774/Metaforce%20logo%20web.png"
                alt="CenterPoint Logo" />

              <SubscriptionComponent />
            </div>
          </div>
          {children}
        </main>
      </div>
    </div>
  );
}

export default BaseLayout;