import { useContext, useEffect, useRef, useState } from "react";
import { OutageContext } from "../../../contexts";
import { formatDate } from "../../../utils/dateUtil";
import "./styles.scss"
import TooltipsOutageComponent from "./tooltipsOutageComponent";
import { Link } from "react-router-dom";


export default function TooltipsComponent() {
    const [eventsOnDate, setEventsOnDate] = useState();
    const { statusBarMouseEntered, tooltipsEventsOnDateRef, tooltipsStyle } = useContext(OutageContext);
    const [delayHandler, setDelayHandler] = useState(null);
    const mouseEnteredRef = useRef(false);

    const handleCloseTooltips = () => {
        setEventsOnDate(null);
        clearTimeout(delayHandler);
        mouseEnteredRef.current = false;
        tooltipsEventsOnDateRef.current = null;
    }

    useEffect(() => {
        if (statusBarMouseEntered) {
            setEventsOnDate(tooltipsEventsOnDateRef.current);
        } else {
            setDelayHandler(setTimeout(() => {
                if (!mouseEnteredRef.current) {
                    handleCloseTooltips();
                }
            }, 250))
        }
    }, [statusBarMouseEntered])

    if (!eventsOnDate) {
        return <></>
    }

    return (
        <div
            className="uptime-tooltip"
            onMouseEnter={() => { mouseEnteredRef.current = true; }}
            onMouseLeave={() => handleCloseTooltips()}
        >
            <div className="pointer-container" style={tooltipsStyle.arrow}>
                <div className="pointer-larger"></div>
                <div className="pointer-smaller"></div>
            </div>

            <div className="tooltip-box" style={tooltipsStyle.text}>
                <h3 className="text-md font-semibold mb-3" >{eventsOnDate?.date ? formatDate(eventsOnDate.date) : ""}</h3>

                {eventsOnDate?.events?.length === 0 ?
                    <div className="text-md">
                        No downtime recorded on this day.
                    </div>
                    :
                    <div>
                        {eventsOnDate?.events?.map((event) => {
                            return <TooltipsOutageComponent key={event.id} event={event}></TooltipsOutageComponent>
                        })}
                    </div>
                }

                {eventsOnDate?.incidents?.length > 0 &&
                    <div>
                        <div className="my-2 text-sm text-gray-400 uppercase">Related</div>
                        {eventsOnDate?.incidents?.map((incident) => {
                            return (
                                <Link key={incident.id}
                                    className="block text-sm hover:underline mb-1"
                                    to={`/incident/${incident.id}`}
                                >
                                    {incident.title}
                                </Link>
                            )
                        })}
                    </div>
                }

            </div>
        </div>
    )
}