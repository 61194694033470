import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getIncident } from "../../api/incident";
import { incidentStatus, servicesTemplate } from "../../utils/constants";
import { formatDateTime } from "../../utils/dateUtil";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { getServices } from "../../api/service";

const IncidentReport = () => {
    const { incidentId } = useParams();
    const history = useHistory();
    const [incident, setIncident] = useState();
    const [logs, setLogs] = useState([]);
    const [affectedServices, setAffectedServices] = useState('');

    const sortLogsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    const loadData = async () => {
        const [servicesResult, incidentResult] = await Promise.all([
            getServices(),
            getIncident(incidentId),
        ]);
        setIncident(incidentResult);
        setLogs(incidentResult.logs ? sortLogsByDate(incidentResult.logs) : [])
        let as = '';
        const services = servicesTemplate(servicesResult.services);
        incidentResult.affectedServices?.forEach((service) => {
            if (!services[service]) return;
            as += services[service].name + ', ';
        })
        if (as.endsWith(', ')) {
            as = as.substring(0, as.length - 2);
        }
        setAffectedServices(as);
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <div className={'h-screen'}>
            <div className={`container max-w-screen-md mx-auto p-20 flex flex-col`}>
                <main className="">
                    <h1 className="text-4xl text-red-600 font-medium text-center mt-10 mb-3">{incident?.title}</h1>
                    <h2 className="text-2xl text-gray-400 text-center mb-10">Incident Report for Metaforce</h2>
                    <div className="grid grid-cols-4 gap-8">
                        {logs.map((log) => {
                            return (
                                <>
                                    <div className="col-span-1 text-lg font-medium">{incidentStatus[log.status]}</div>
                                    <div className="col-span-3">
                                        <div className="text-md">{log.title}</div>
                                        <div className="text-sm text-gray-400">{formatDateTime(log.createdDate)}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    <div className="border rounded-md shadow-sm px-6 py-3 mt-10 text-gray-400 text-sm">
                        This incident affected: {affectedServices}
                    </div>

                    <div className="border-gray-200 border-t mt-10">
                        <button className="mt-2 border-none inline-flex items-center gap-x-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            <ArrowNarrowLeftIcon className="h-4 w-4" />
                            Current Status
                        </button>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default IncidentReport;