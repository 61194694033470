export const status = {
  available: 0,
  outage: 1,
}

const getEventsOn90Days = () => {
  return [...Array(90).keys()].map(x => {
    const date = new Date();
    date.setDate(date.getDate() - x);
    return {
      date: date,
      events: [],
      incidents: [],
    };
  }).reverse()
}

export const servicesTemplate = (services) => {
  const template = {};
  services && Object.keys(services).forEach((service) => {
    template[service] = {
      name: services[service],
      eventsOn90Days: getEventsOn90Days(),
    }
  })
  return template;
}

export const incidentsOn3MonthsTemplate = (currentDate) => {
  return [...Array(3).keys()].map((x, index) => {
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth() - x, 5);
    return {
      date: date,
      incidents: [],
    };
  });
}

export const incidentStatus = {
  0: 'Investigating',
  1: 'Monitoring',
  2: 'Update',
  3: 'Identified',
  4: 'Resolved',
}