import { useHistory } from "react-router-dom";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import MonthsPagination from "./components/monthsPagination";
import { useEffect, useState } from "react";
import { sameMonth } from "../../utils/dateUtil";
import { incidentsOn3MonthsTemplate } from "../../utils/constants";
import { searchIncidents } from "../../api/incident";
import HistoryIncidentsComponent from "./components/historyIncidentsComponent";

export const IncidentHistory = () => {
    const history = useHistory();
    const [incidentsOn3Months, setIncidentsOn3Months] = useState(incidentsOn3MonthsTemplate(new Date()));

    const sortIncidentsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    const handleSearchIncidents = async (startTime, endTime) => {
        const sTime = new Date(startTime.getFullYear(), startTime.getMonth(), 1, 0, 0, 0);
        const eTime = new Date(endTime.getFullYear(), endTime.getMonth() + 1, 0, 23, 59, 59);

        const result = await searchIncidents({
            text: '',
            startTime: sTime, 
            endTime: eTime
        });

        let newIncidentsOn3Months = incidentsOn3MonthsTemplate(endTime);
        result.forEach((incident) => {
            const found = newIncidentsOn3Months.find(x => sameMonth(x.date, incident.createdDate));
            if (found) {
                found.incidents.push(incident);
            }
        });
        newIncidentsOn3Months[0].incidents = sortIncidentsByDate(newIncidentsOn3Months[0].incidents);
        newIncidentsOn3Months[1].incidents = sortIncidentsByDate(newIncidentsOn3Months[1].incidents);
        newIncidentsOn3Months[2].incidents = sortIncidentsByDate(newIncidentsOn3Months[2].incidents);
        setIncidentsOn3Months(newIncidentsOn3Months);
    }

    const loadData = async () => {
        await handleSearchIncidents(incidentsOn3Months[2].date, incidentsOn3Months[0].date);
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleMonthChanged = async (newIncidentsOn3Months) => {
        await handleSearchIncidents(newIncidentsOn3Months[2].date, newIncidentsOn3Months[0].date)
    }

    return (
        <div >
            <h2 className="text-2xl flex-1 py-3">Incidents</h2>

            <div className="grid justify-items-end">
                <MonthsPagination incidentsOn3Months={incidentsOn3Months} onClick={handleMonthChanged} />
            </div>

            {incidentsOn3Months.map((incidentsOnMonth, index) => {
                return (
                    <HistoryIncidentsComponent key={index} incidentsOnMonth={incidentsOnMonth}/>
                )
            })}

            <div className="border-gray-200 border-t mt-5">
                <button className="mt-2 border-none inline-flex items-center gap-x-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    <ArrowNarrowLeftIcon className="h-4 w-4" />
                    Current Status
                </button>
            </div>

        </div>
    )
}