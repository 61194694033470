import { get, post, setStatusApiKey } from './api';

const controllerName = 'Subscriptions';

export const getSubscription = async (id) => {
    setStatusApiKey();
    return await get(`${controllerName}/${id}`);
}

export const subscribeEmail = async (data) => {
    setStatusApiKey();
    return await post(`${controllerName}/subscribe`, data);
}

export const confirmSubscription = async (id) => {
    setStatusApiKey();
    return await get(`${controllerName}/confirm/${id}`);
}

export const unsubscribeEmail = async (id) => {
    setStatusApiKey();
    return await get(`${controllerName}/unsubscribe/${id}`);
}