import { get } from './api';

const controllerName = 'events';

export const getEvents = async ()=>{
    return await get(controllerName);
}

export const getEvent = async (id)=>{
    return await get(controllerName + "/" + id);
}
