import StatusComponent from "./statusComponent"

export default function ServiceStatusComponent({ service }) {
    const calculateUptime = () => {
        let minutesOfDowntime = 0;
        service.eventsOn90Days?.forEach(eventsOnDate => {
            eventsOnDate.events?.forEach(event => {
                let startTime = !event.startTime ? new Date() : new Date(event.startTime);
                let endTime = !event.endTime ? new Date() : new Date(event.endTime);
                minutesOfDowntime += (endTime.getTime() - startTime.getTime()) / 1000 / 60;
            })
        });
        let uptimePercentage = 100 - (minutesOfDowntime / (24 * 60 * 90))
        // round down 99,9998765432 -> 99.99
        return (Math.floor(uptimePercentage * 100) / 100).toFixed(2);
    }

    return (
        <div className="px-6 py-3">
            <div className="py-2">
                <span className="text-lg">{service.name}</span>
            </div>

            <div className="">
                <svg className="w-full" id="uptime-component-scd0s93nldpb" preserveAspectRatio="none" height="34" tabindex="0" viewBox="0 0 448 34">
                    {
                        service.eventsOn90Days.map((eventsOnDate, index) => {
                            return (
                                <StatusComponent key={index} x={index * 5} eventsOnDate={eventsOnDate}></StatusComponent>
                            )
                        })
                    }
                </svg>
            </div>

            <div className="text-gray-300 text-sm py-3 flex flex-row">
                <span>90 days ago</span>
                <div className="flex-1 h-px mx-4 my-3 bg-gray-300"></div>
                <span>{calculateUptime()} % uptime</span>
                <div className="flex-1 h-px mx-4 my-3 bg-gray-300"></div>
                <span>Today</span>
            </div>
        </div>
    )

}