import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import { useSearchParam } from 'react-use';
import { XIcon } from '@heroicons/react/outline';
import { useToastAction } from '../../../hooks/useToastAction';
import { getSubscription, unsubscribeEmail } from '../../../api/subscription';

const UnsubscribeComponent = () => {
  const unsubscribeId = useSearchParam('unsubscribe');
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState('');

  const loadAction = useToastAction();
  const unsubscribeAction = useToastAction();

  const loadData = async () => {
    loadAction.execute(async () => {
      if (unsubscribeId) {
        const result = await getSubscription(unsubscribeId);
        if (result) {
          setSubscription(result);
          setOpen(true);
        }
      }
    }, 'Failed to load email address');
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleUnsubscribe = async () => {
    unsubscribeAction.execute(async () => {
      setOpen(false);
      await unsubscribeEmail(subscription.id);
    },
      'Failed to unsubscribe',
      'You have been successfully unsubscribed and will not receive any further notifications.'
    );
  }

  return (
    <Modal
      isOpen={open}
      onClose={() => { setOpen(false) }}
      size="medium"
    >
      <div>
        <div className="border-b border-gray-200 flex justify-between px-5 py-3">
          <div className="text-2xl font-medium">Unsubscribe</div>
          <button
            type="button"
            className="border-none inline-flex items-center py-2"
            onClick={() => { setOpen(false) }}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="border-b border-gray-200 text-base px-5 py-5">
          Click the confirm button below to unsubscribe <strong>{subscription?.email}</strong> from all <strong>Metaforce</strong> updates.
        </div>
        <div className="flex justify-end bg-gray-50 px-5 py-3">
          <button
            className="border rounded-md shadow-sm inline-flex items-center px-4 py-2 text-xs text-white font-medium bg-red-500 uppercase"
            onClick={handleUnsubscribe}>
            Unsubscribe from updates
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UnsubscribeComponent;