import { Link } from "react-router-dom";
import { incidentStatus } from "../../../utils/constants"
import { formatDate, formatDateTime } from "../../../utils/dateUtil";

export default function HomeIncidentsComponent({ incidentsOnDate }) {
    const sortIncidentsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    const sortIncidentLogsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    return (
        <div className="py-5" >
            <div className="border-gray-200 border-b">
                <h3 className="text-lg">{formatDate(incidentsOnDate.date)}</h3>
            </div>
            {
                incidentsOnDate.incidents?.length > 0
                    ?
                    sortIncidentsByDate(incidentsOnDate.incidents).map(incident => {
                        return (
                            <div className="py-3" key={incident.id}>
                                <Link
                                    to={`/incident/${incident.id}`}
                                    className="text-lg font-medium text-red-600 hover:text-red-700 mb-2"
                                >
                                    {incident.title}
                                </Link>
                                {incident.logs && sortIncidentLogsByDate(incident.logs).map((log, index) => {
                                    return (
                                        <div className="mb-3" key={index}>
                                            <div className="text-md">
                                                <span className="font-medium">{incidentStatus[log.status]} - </span>
                                                <span>{log.title}</span>
                                            </div>
                                            <div className="text-sm text-gray-400">{formatDateTime(log.createdDate)}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                    :
                    <div className="text-gray-400 mt-2 text-sm" >No incidents reported.</div>
            }
        </div>

    )
}