import { useEffect, useState } from "react";
import { formatDateTime } from "../../../utils/dateUtil";
import { Link } from "react-router-dom";

export default function IncidentComponent({ incident }) {
    const [lastLog, setLastLog] = useState();

    const sortIncidentLogsByDate = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
    }

    useEffect(() => {
        if (incident.logs?.length > 0) {
            const logs = sortIncidentLogsByDate(incident.logs);
            setLastLog(logs[0]);
        }
    }, [incident])

    return (
        <div className="py-4">
            <Link
                to={`/incident/${incident.id}`}
                className="text-lg font-medium text-red-600 hover:text-red-700 mb-1"
            >
                {incident.title}
            </Link>
            {lastLog && (
                <>
                    <div className="text-md ">{lastLog.title}</div>
                    <div className="text-sm text-gray-400 ">{formatDateTime(lastLog.createdDate)}</div>
                </>
            )}
        </div>
    )
}