import { get, BaseUrl, post } from './api';

const controllerName = 'incidents';

export const searchIncidents = async (data)=>{
    return await post(`${controllerName}/search`, data);
}

export const getIncident = async (id)=>{
    return await get(controllerName + "/" + id);
}